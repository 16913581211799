/* general-page- attributes */
.App {
  text-align: center;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  display: inline-block;
  max-width: 1150px;
}

.App-link {
  color: #61dafb;
}

@font-face {
  font-family: jannscript;
  src: url(./fonts/jannscript.otf);
}

@font-face {
  font-family: barrio;
  src: url(./fonts/Barrio-Regular.otf);
}

/* logo */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5s linear;
  }
}

@keyframes App-logo-spin {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(13deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-13deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* elements */
.buttons {
  padding-bottom: 20px;
}

.social_icons {
  margin-top: 20px;
}

img {
  padding: 8px;
}

/* fonts */
#slo {
  font-family: jannscript;
  margin-right: 10px;
  background: none !important;
  border: none;
  padding: 0 !important;
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

#en {
  font-family: jannscript;
  margin-right: 10px;
  background: none !important;
  border: none;
  padding: 0 !important;
  color: gray;
  text-decoration: none;
  cursor: pointer;
}

h2 {
  font-family: barrio;
  font-size: 28px;
  color: #525252;
}

h5 {
  font-family: jannscript;
  font-weight: 500;
  font-size: 18px;
}

a {
  font-family: jannscript;
  color: gray;
  font-size: 16px;
  text-decoration: none;
  margin-bottom: 20px;
}

p {
  font-family: jannscript;
  color: gray;
  font-size: 16px;
  margin-bottom: 20px;
}
